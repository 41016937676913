import React from "react";
import styled from "styled-components";

import poemAudio from "Assets/poem.m4a";
import bark1 from "Assets/b1.jpg";
import bark2 from "Assets/b2.jpg";
import bark3 from "Assets/b3.jpg";
import bark4 from "Assets/b4.jpg";
import bark5 from "Assets/b5.jpg";

import video1 from "Assets/video1.mp4";
import video2 from "Assets/video2.mp4";
import video3 from "Assets/video3.mp4";
import video4 from "Assets/video4.mp4";

const Content = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 0;
    pointer-events: none;
    z-index: 2;
`;

const ContentInside = styled.div`
    padding: 2rem 4rem;
    width: 100%;
    pointer-events: ${(props) => (props.isOff ? "none" : "auto")};

    @media screen and (max-width: 640px) {
        padding: 0.5rem 1rem;
    }
`;

const BarkImage = styled.img`
    width: calc(100% - 4rem);
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 #ced8b9, 0 2px 9px 0 #cdd8bc;
    vertical-align: bottom;
    margin-bottom: 8px;
    opacity: ${(props) => (props.isOn ? 1 : 0.03)};
    filter: ${(props) => (props.isOn ? "none" : "blur(2px)")};
    pointer-events: ${(props) => (props.isOn ? "auto" : "none")};
    transition: opacity 0.1s ease;
    pointer-events: none;
    user-select: none;

    @media screen and (max-width: 640px) {
        width: calc(100% - 1rem);
    }
`;

const BarkImages = styled.div`
    position: absolute;
    z-index: 3;
`;

const VideoPoems = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 4;
    padding: 2rem;
    @media screen and (max-width: 640px) {
        padding: 0.5rem;
    }
`;

const Video = styled.video`
    border-radius: 8px;
    width: 67.6%;
    margin: 4rem 0;
    box-shadow: 0 8px 10px 0 #ec585b;

    opacity: ${(props) => (props.isOn ? 1 : 0.15)};
    transition: opacity 0.2s ease;
    filter: ${(props) => (props.isOn ? "none" : "blur(4px)")};

    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;

const VideoRight = styled(Video)`
    align-self: flex-end;
`;

const Poetry = styled.div`
    font-family: "century_supra_t4";

    @media screen and (min-width: 1025px) {
        font-size: 1.2rem;
    }

    line-height: 1.6rem;
    backdrop-filter: ${(props) => (props.isOn ? "blur(20px)" : "none")};
    background: rgba(255, 255, 255, 0.2);
    margin: 6rem auto;
    max-width: 43em;
    width: 100%;
    padding: 2rem 4rem;
    border-radius: 8px;

    opacity: ${(props) => (props.isOn ? 1 : 0.15)};
    transition: opacity 0.2s ease;
    filter: ${(props) => (props.isOn ? "none" : "blur(4px)")};
    pointer-events: auto;

    @media screen and (max-width: 640px) {
        padding: 0.75rem;
        margin: 3rem auto;
    }

    audio {
        margin: 0 auto 2rem;
        display: block;
    }
`;
const PoetryLine = styled.div``;
const PoetryLineIndent = styled(PoetryLine)`
    margin-left: 3rem;
`;

const barkImgs = [bark1, bark2, bark3, bark4, bark5];

export default ({ contentRef, isShowingArchive, numberOfTiles }) => (
    <>
        <Content ref={contentRef} isOff={isShowingArchive}>
            <ContentInside isOff={isShowingArchive}>
                <BarkImages>
                    {[...Array(numberOfTiles)].map((e, i) => (
                        <BarkImage
                            key={i}
                            isOn={!isShowingArchive}
                            src={barkImgs[i % barkImgs.length]}
                        />
                    ))}
                </BarkImages>
                <VideoPoems>
                    <Video
                        isOn={!isShowingArchive}
                        muted
                        autoPlay
                        loop
                        playsInline
                    >
                        <source src={video1} />
                    </Video>
                    <Poetry isOn={!isShowingArchive}>
                        <audio src={poemAudio} controls />
                        <PoetryLineIndent>
                            Hxstories held in languages spoken and visual
                            pulsate between layers
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            Of bamboo cotton, river reed, floral bark and
                            synthetic fibres
                        </PoetryLineIndent>
                        <PoetryLine>
                            Cumlines gesture to stellar arrays through sweaty
                            dances
                        </PoetryLine>
                        <PoetryLine>
                            Shimmering crystals and salt, plants and fruits,
                            hold collective mālamalama
                        </PoetryLine>
                        <PoetryLine>
                            And back through nine heavens and three earths, to
                            pulotu
                        </PoetryLine>
                        <PoetryLineIndent>
                            Genealogical epics whispered by screenlight,
                            speculative possible worlds
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            Massaged by tamanu, long•sought bearings in lands
                            subsumed, still sacred
                        </PoetryLineIndent>
                    </Poetry>
                    <VideoRight
                        isOn={!isShowingArchive}
                        playsInline
                        muted
                        autoPlay
                        loop
                    >
                        <source src={video2} />
                    </VideoRight>
                    <Poetry isOn={!isShowingArchive}>
                        <PoetryLine>
                            Handmaking renders systemic alibis lightweight
                            against breadth of knowing
                        </PoetryLine>
                        <PoetryLine>
                            Cooking continents, aromas tenderly guarded to
                            Ancestor and Kin presences
                        </PoetryLine>
                        <PoetryLineIndent>
                            We are not artefacts and objects; which are lies in
                            Middle•Earth containment
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            We are not feathers and leather; reparations in
                            Indigenous•World balance
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            We wear rivers of song and verse, an anchor for who
                            is to come
                        </PoetryLineIndent>
                        <PoetryLine>
                            Tilting heads, really seeing each other, and you
                            floating through the mist
                        </PoetryLine>
                        <PoetryLine>
                            This hearth to change all prior landgrabs and
                            declamations
                        </PoetryLine>
                    </Poetry>
                    <Video
                        isOn={!isShowingArchive}
                        playsInline
                        muted
                        autoPlay
                        loop
                    >
                        <source src={video3} />
                    </Video>
                    <Poetry isOn={!isShowingArchive}>
                        <PoetryLineIndent>
                            I became a cousin seed of Ra’iātea in the
                            constellation of this time
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            Desiring freedoms and self•determination beyond
                            regimes and dominations
                        </PoetryLineIndent>
                        <PoetryLine>
                            Images edited, records silenced, languages dimmed
                            but you know that
                        </PoetryLine>
                        <PoetryLine>
                            Intersectional queer feminist decolonial ways
                            couldn’t be obfuscated forever
                        </PoetryLine>
                        <PoetryLine>
                            Across the skin•that•nourishes surpassing empires
                            that didn’t last
                        </PoetryLine>
                        <PoetryLineIndent>
                            Those fumbling over surveilled zones have since been
                            subsumed by water•time
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            Building blocks of renewal and pushing back skylines
                        </PoetryLineIndent>
                    </Poetry>
                    <VideoRight
                        isOn={!isShowingArchive}
                        playsInline
                        muted
                        autoPlay
                        loop
                    >
                        <source src={video4} />
                    </VideoRight>
                    <Poetry isOn={!isShowingArchive}>
                        <PoetryLine>
                            Those of us yams and taro thriving on the edges, the
                            liminal homes of deities
                        </PoetryLine>
                        <PoetryLine>
                            Bound by waves of Garrigarrang to towns forever
                            Birrarung•ga, nipaluna, Garrmalang
                        </PoetryLine>
                        <PoetryLineIndent>
                            Accommodating liaisons as we scheme across captive
                            anguish{" "}
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            Carceral geopolitics used to infuse toponymic curves
                            in cold coloniality
                        </PoetryLineIndent>
                        <PoetryLineIndent>
                            We died many times and mourned, worlding, reaching
                            across
                        </PoetryLineIndent>
                        <PoetryLine>
                            To hold bespecked atoll universes of neighbours, to
                            shelter in genuine cloths
                        </PoetryLine>
                        <PoetryLine>
                            Beautiful ‘ie of fernbuds, laughter, cacaonibs,
                            pétillants naturels, senses
                        </PoetryLine>
                    </Poetry>
                </VideoPoems>
            </ContentInside>
        </Content>
    </>
);
