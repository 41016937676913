import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    :root {
        --color-line: rgba(164,210,241,.5);
        --color-blue-button: rgba(140,182,211,0.75);
        --color-blue-button-hover: rgba(140,182,211,0.9);
        --color-blue-text: #276088;
        --color-red-button: rgba(237,89,92,0.8);
        --color-red-button-hover: rgba(237,89,92,1);
        --color-red-button-off: rgba(237,89,92,0.25);
        --color-red-button-off-hover: rgba(237,89,92,0.3);
    }

    html {
        font-size: 100%;
        line-height: 1.4rem;
        color: rgba(2,29,50, 0.9);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        
        @media (min-width:1024px) {
            font-size: 112.5%;
        }

        @media (min-width:1281px) {
            font-size: 125%;
        }
    }

    html, button, input {
        font-family: Rubik;
    }

    * {
        box-sizing: border-box;
    }
`;
