import React, { useRef, useState } from "react";
import styled from "styled-components";

const Intro = styled.div`
    font-family: "century_supra_t4";
    line-height: 1.5rem;
    font-size: 1.05rem;
    margin: 0 2rem;
    padding: 4vh 2rem;
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--color-line);
    border-top: 0;
    position: relative;

    @media screen and (max-width: 640px) {
        padding: 0.75rem;
        margin: 0 0.5rem;
    }

    p {
        margin-bottom: 0.5rem;
    }

    h2 {
        all: unset;
        cursor: pointer;
        font-family: rubik;
        font-size: 0.7rem;
        font-weight: 600;
        font-weight: normal;
        letter-spacing: 1.75px;
        line-height: 1.6rem;
        margin: 0 0 2rem;
        text-transform: uppercase;
        @media screen and (max-width: 640px) {
            margin: 2rem 0 0;
        }
    }
`;

const IntroInside = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    padding-top: 2rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

const IntroLeft = styled.div`
    flex: 0 1 67%;
    padding-right: 4rem;
    @media screen and (max-width: 640px) {
        padding-right: 0;
    }
`;

const IntroRight = styled.div`
    flex: 1 0 33%;
    font-family: rubik;
    font-size: 0.95rem;
    line-height: 1.4rem;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            color: #f2333f;
        }
    }
`;

const Credits = styled.div`
    font-size: 0.8rem;
    a {
        color: inherit;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: rgba(0, 0, 0, 0.3);
    }
`;

const IntroTop = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    button {
        all: unset;
        cursor: pointer;
        color: #f2333f;
        display: inline;
    }
`;

const IntroHeight = styled.div`
    height: ${(props) =>
        props.show ? `${props.inner.current.scrollHeight}px` : "0px"};
    overflow: hidden;
    transition: all 0.5s ease;
`;

const IntroSwitcher = styled.div`
    display: flex;
`;

const IntroSwitcherButton = styled.button`
    all: unset;
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    height: 2.2em;
    font-family: rubik;
    font-size: 0.7rem;
    font-weight: 600;
    font-weight: normal;
    letter-spacing: 1.75px;
    line-height: 1.6rem;
    margin-right: 0.5rem;
    padding: 0 0.7rem;
    text-transform: uppercase;
    white-space: nowrap;

    background: ${(props) =>
        props.isSelected ? "rgba(0,0,0,0.05)" : "transparent"};

    &:hover {
        background: ${(props) =>
            props.isSelected ? "rgba(0,0,0,0.05)" : "rgba(0,0,0,0.02)"};
    }

    @media screen and (max-width: 640px) {
        margin: 2rem 0 0;
    }
`;

const IntroText = styled.div`
    button {
        all: unset;
        cursor: pointer;
        color: #f2333f;
        display: inline;
    }
`;

export default ({ setIsShowingArchive, setIsShowingIntro, isShowingIntro }) => {
    const innerRef = useRef();
    const [isShowingAbout, setIsShowingAbout] = useState(false);
    return (
        <Intro>
            <IntroTop>
                <div>
                    Afifio mai, susū mai, maliu mai / Welcome everyone of
                    various status
                </div>
                <button onClick={() => setIsShowingIntro(!isShowingIntro)}>
                    {isShowingIntro ? "Hide the" : "Read the"} introduction …
                </button>
            </IntroTop>
            <IntroHeight show={isShowingIntro} inner={innerRef}>
                <IntroInside ref={innerRef}>
                    <IntroLeft>
                        <IntroSwitcher>
                            <IntroSwitcherButton
                                isSelected={!isShowingAbout}
                                onClick={() => setIsShowingAbout(false)}
                            >
                                Introduction
                            </IntroSwitcherButton>
                            <IntroSwitcherButton
                                isSelected={isShowingAbout}
                                onClick={() => setIsShowingAbout(true)}
                            >
                                About the artwork
                            </IntroSwitcherButton>
                        </IntroSwitcher>
                        {!isShowingAbout ? (
                            <IntroText>
                                <p>
                                    AOAULI affirms my intention to locate,
                                    connect with, and honour the measina,
                                    treasured handcrafted belongings, and iloa,
                                    genealogical and ceremonial-political
                                    knowledges, that are imbued in playful and
                                    rare works of lama, candlenut ink, and uʻa,
                                    paper mulberry bark. Siapo mamanu, barkcloth
                                    marked with designs by hand, and siapo
                                    tasina, barkcloth marked by pressing relief
                                    motifs, continue to be made by Sāmoans and
                                    many other Indigenous peoples of the Great
                                    Ocean.
                                </p>
                                <p>
                                    Nonetheless, our cultural genealogies remain
                                    disrupted and imbalanced by the absences and
                                    estrangement experienced with the vast
                                    majority of our measina being held cold and
                                    sterile in European and North American
                                    public museums, archives, libraries, and
                                    private collections. Perhaps in 2025, my
                                    deeply divided, colonised and traumatised
                                    Sāmoan people and archipelago will sound,
                                    look and feel softer and kinder.
                                </p>
                                <p>
                                    The dizzying thousands upon thousands of
                                    siapo mamanu and siapo tasina — made by
                                    Ancestors with their bodies in these Western
                                    epistemic and political institutions of
                                    sanctioned culture — haunt us, their
                                    descendants, as tangible archives of their
                                    making between the 1700s and 1960s. In other
                                    words, from before Western temporality,
                                    morality, centralised governance,
                                    plantations, capitalism and Christian shame;
                                    from before multiple clan-based polities
                                    based on responsible, deliberative
                                    decision-making and close kinship with
                                    lands, waters, skies, and the entire
                                    beyond-human world.
                                </p>
                                <p>
                                    The siapo viliata, animated barkcloth, as
                                    videos linked by poetic verses, you see here
                                    are an elegy to the ingenuity and creativity
                                    of the Ancestors of all Sāmoan peoples. Like
                                    me, the majority grow up dislocated
                                    Indigenous peoples of the diaspora,
                                    sometimes at home in the voyage, sometimes
                                    torn between conflicting epistemic and
                                    political frameworks. We are, despite us,
                                    enmeshed in the settler colonial and
                                    militourist industrial complexes that
                                    dispossess kin First Peoples in Australia,
                                    New Zealand, United States and Canada. We
                                    are not living in large communities in close
                                    proximity to the measina of our Ancestors in
                                    most of the many places where they are kept.
                                </p>
                                <p>
                                    AOAULI is an offering to the Ancestors and
                                    to the young who realise the futures of
                                    wellness and balance we so desperately need
                                    and hopefully deserve. AOAULI is both a
                                    genealogy carefully and fallibly assembled
                                    by myself of our hxstories of visuality, and
                                    an embodied visual marking of the vā,
                                    relational space, that my body moves through
                                    with other living beings as it visits
                                    Mparntwe in occupied Arrernte Apmere, and
                                    returns form to collectively shared Sāmoan
                                    motifs and patterns thousands of years
                                    young.
                                </p>
                            </IntroText>
                        ) : (
                            <IntroText>
                                <p>
                                    AOAULI, meaning midday in gagana Sāmoa, is
                                    part of a new series of animated barkcloth,
                                    called siapo viliata. Conceiving the screen
                                    as a futurist loom, AOAULI is a work of
                                    visual literature which comes to us from the
                                    ‘recent future’ of 2025 to explore circular
                                    time, relational space, and embodied
                                    knowledge. Sensual and tender movements of
                                    the body and the land align with linework
                                    tracing animal and bird deities known to us
                                    for thousands of years before plantations,
                                    commerce, chastity and shame arrived in the
                                    Sāmoan archipelago.
                                </p>
                                <p>
                                    My body shimmers in the thermal waves of
                                    heat, and glistens in the sun, marked with
                                    motifs carried always on our bodies in tatau
                                    form, and wrapped around our bodies in siapo
                                    form. I draw on the visual literature of
                                    siapo mamanu and siapo tasina captive in
                                    public museums, libraries and archives in
                                    Germany, Sweden, United States, United
                                    Kingdom, Australia and New Zealand. I draw
                                    on futures where political borders,
                                    religious control, economic domination and
                                    cultural erasure have weakened, where we
                                    return to who we are and can be,
                                    understanding how to continue to innovate
                                    all our cultures in pluralist societies.
                                    AOAULI considers this most recent ‘fall’ of
                                    catastrophes and pandemics within much
                                    longer Sāmoan hxstories of visuality and
                                    cultural practice. I hope it can be a place
                                    to meet and cut through the physical
                                    separation and divisions, so that
                                    relationality and care are tools for
                                    realising the futures imagined and led by
                                    Sāmoan and other Indigenous peoples of the
                                    Great Ocean and its shores.
                                </p>

                                <p>
                                    – Léuli Eshrāghi
                                    <br />
                                    <br />
                                </p>

                                <p>
                                    <em>Short biography</em>
                                    <br />
                                    Dr Léuli Eshrāghi is a
                                    Sāmoan-Persian-Australian artist, curator
                                    and researcher committed to centring
                                    Indigenous presence and power, sensual and
                                    spoken languages, and ceremonial-political
                                    practices. Through performance, moving
                                    image, writing and installation, Eshrāghi
                                    engages with Indigenous possibility as
                                    haunted by ongoing ‘militourist’ and
                                    missionary violence that erase
                                    faʻafafine-faʻatama from kinship structures.
                                    Eshrāghi is the inaugural Horizon
                                    postdoctoral fellow with the Initiative for
                                    Indigenous Futures led by Jason Edward Lewis
                                    at Concordia University, a member of The
                                    Space Between Us international research
                                    partnership led by Dr Julie Nagam at the
                                    University of Winnipeg, and board member of
                                    the Aboriginal Curatorial
                                    Collective/Collectif des commissaires
                                    autochtones.
                                </p>
                            </IntroText>
                        )}
                    </IntroLeft>
                    <IntroRight>
                        <h2>Fa‘atautaiga / Navigation</h2>
                        <p>
                            AOAULI comprises four layers: barkcloth drawings
                            abstracted from museum collections, performance
                            videos shot in Arrernte Country, a time-travelling
                            poem, and, underneath those, a research archive.
                        </p>
                        <p>
                            Use the controls in the top right to switch between
                            layers as you engage with AOAULI.
                        </p>
                        <p>&nbsp;</p>
                        <Credits>
                            <p>
                                This project by{" "}
                                <a
                                    target="_blank"
                                    href="http://leulieshraghi.art/"
                                    rel="noopener noreferrer"
                                >
                                    Léuli Eshrāghi
                                </a>{" "}
                                for{" "}
                                <a
                                    target="_blank"
                                    href="//open.acca.melbourne"
                                    rel="noopener noreferrer"
                                >
                                    ACCA Open
                                </a>{" "}
                                was generously assisted by{" "}
                                <a
                                    target="_blank"
                                    href="//rmcnaught.com"
                                    rel="noopener noreferrer"
                                >
                                    Rowan McNaught
                                </a>
                                , Stuart Miller, Alexandra Grisedale, Nadeem
                                Tiafau Eshrāghi, and Dane Brookes, with
                                curatorial development by Max Delany, and with
                                cultural consultation by Angela Tiatia and
                                Mitiana Arbon. The production of AOAULI began in
                                Nhulunbuy and was completed in Mparntwe, Meanjin
                                and Birrarung-ga.
                            </p>

                            <p>
                                ACCA Open commissions are supported in part by
                                Creative Victoria’s Strategic Investment Fund.
                                ACCA also acknowledges the ongoing support of
                                Creative Victoria, Australia Council for the
                                Arts, Visual Arts and Craft Strategy, and the
                                City of Melbourne.
                            </p>
                        </Credits>
                    </IntroRight>
                </IntroInside>
            </IntroHeight>
        </Intro>
    );
};
