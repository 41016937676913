import React from "react";
import styled from "styled-components";

import ReactMarkdown from "react-markdown";

import archiveItems from "archiveItems.json";

const ResearchInside = styled.div`
    position: relative;
    padding: 0 2rem;
    transition: opacity 0.2s ease;
    pointer-events: ${(props) => (props.isOn ? "auto" : "none")};

    ::after {
        position: absolute;
        bottom: 2rem;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: var(--color-line);
    }

    @media screen and (max-width: 640px) {
        padding: 0 0.5rem;
    }
`;

const ResearchColumns = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 640px) {
        flex-direction: column;
        border-left: 1px solid var(--color-line);
        border-right: 1px solid var(--color-line);
    }
`;

const ResearchItem = styled.a`
    color: inherit;
    text-decoration: none;
    box-shadow: 0 0px 0px 0 white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    /* font-size: 0.9rem; */
    flex: 1 1 10em;
    border-top: 1px solid var(--color-line);
    padding: 1em;
    overflow: hidden;
    transition: all 0.2s ease;

    &:hover {
        border-top: 1px solid transparent;
        box-shadow: 0 4px 10px 0 #ec585b;
    }

    @media screen and (max-width: 640px) {
        flex: 1 1 auto;
    }
`;

const Research = styled.div`
    font-family: "century_supra_t4";
    line-height: 1.5rem;
    font-size: 1.05rem;

    color: var(--color-blue-text);
    width: 100%;
    line-height: 1.2rem;

    ${ResearchItem} {
        opacity: ${(props) => (props.isShowing ? 1 : 0.25)};
    }

    z-index: 1;
    overflow: hidden;
    height: ${(props) =>
        props.fixedHeight ? `calc(2rem + ${props.fixedHeight}px)` : "auto"};
`;

const ResearchColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    padding-bottom: 2rem;

    p {
        margin: 0;
    }

    :first-child {
        border-left: 2px solid var(--color-line);
    }

    &:nth-child(2) {
        border-left: 2px solid var(--color-line);
        border-right: 2px solid var(--color-line);
    }

    :last-child {
        border-right: 2px solid var(--color-line);
    }
    @media screen and (max-width: 640px) {
        border: 0 !important;
    }
`;

const ResearchIntro = styled.div`
    padding: 5rem 0.75rem 5rem;
    border-left: 1px solid var(--color-line);
    border-right: 1px solid var(--color-line);
    line-height: 1.5rem;
    h2 {
        margin: 2rem 1rem 3rem;
        text-align: center;
        font-size: 1.35rem;
        line-height: 1.75rem;
        font-family: rubik;
        letter-spacing: 1px;
        font-weight: 600;
    }
    blockquote {
        padding-left: 3rem;
        max-width: 44rem;
        margin: 0 auto 0.75rem;
    }
    p {
        margin: 0 auto 0.75rem;
        text-indent: 3rem;
        max-width: 44rem;
    }
    @media screen and (max-width: 640px) {
        padding: 0.75rem;
        p {
            text-indent: 0;
        }
    }
`;

const ResearchHeader = styled.div`
    font-size: 1.2rem;
    line-height: 1.75rem;
    font-family: rubik;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: center;
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 8rem;
    justify-content: center;
    border-top: 1px solid var(--color-line);
`;

export default ({ isShowingArchive, researchRef, contentHeight }) => (
    <>
        <Research
            ref={researchRef}
            fixedHeight={!isShowingArchive ? contentHeight : false}
            isShowing={isShowingArchive}
        >
            <ResearchInside isOn={isShowingArchive}>
                <ResearchIntro>
                    <h2>
                        Tala faʻasolopito a faʻaāliga ata
                        <br />
                        Sāmoan hxstories of visuality: Archive
                    </h2>
                    <p>
                        Just like the attention and gasps in a village
                        ceremonial gathering are held by the deftly woven ʻie
                        tōga, fine pandanus mat, ʻie fau, hibiscus woven cloth,
                        and lengths of beautifully marked siapo mamanu or siapo
                        tasina, these Research Layers educate, inspire and
                        validate the iloa of our Ancestors, and the drive of our
                        young today.
                    </p>

                    <p>
                        At once lisi faʻapapālagi, Western epistemic list, and
                        ata fetū, stellar imagery, these complex essays,
                        photographs, poems, performances, adornments,
                        installations, moving image works, reviews, and
                        suʻesuʻega, sustained research, are much more than the
                        required reading list for an undergraduate
                        outsider-looking-in course maybe titled Introduction to
                        Sāmoan Visual Cultures 101 (though none yet exist for
                        any of the thousands of Indigenous nations of the Great
                        Ocean and its shores). I hope the three Research Layers,
                        Faʻatinoga | Becoming/Making-Body, Faʻamanuiaga |
                        Becoming/Making-Beautiful-Good, and Faʻaleaganuʻu |
                        Becoming/Making-Culture, in some way redress the absence
                        of Sāmoan centres for contemporary art and decolonial
                        practice.
                    </p>

                    <blockquote>
                        <em>
                            Resurgent organizing must create a future generation
                            that never has to ask how to live free, because
                            they’ve never known anything else―a generation that
                            does not know shame, because they are embedded in
                            each other’s light.
                        </em>
                        <br />— As We Have Always Done, Leanne Betasamosake
                        Simpson (2017)
                    </blockquote>

                    <p>
                        Drawing on Michi Saagiig Nishnaabeg scholar and artist
                        Leanne Betasamosake Simpson’s Indigenous futurity of
                        unashamed brilliance, this necessarily reconstructed
                        archive is designed for connection with multiple senses.
                        Sit a while, leave and return, cuppa or coffee in hand,
                        hold in your heart and mind these currents of thought
                        and creative practices realised in recent decades by
                        this constellation of my kin. Note that all entries are
                        organised by contributor last name and the
                        missionary-imposed Sāmoan alphabet (a e i o u fa ga la
                        mo nu pi sa ti vi he ka ro), with other letters
                        following this order.
                    </p>
                </ResearchIntro>
                <ResearchColumns>
                    {archiveItems.map((column) => (
                        <ResearchColumn key={column.name}>
                            <ResearchHeader>
                                {column.name}
                                <br />
                                {column.subname}
                            </ResearchHeader>
                            {column.items.map((item) => (
                                <ResearchItem
                                    href={item.link}
                                    target="_blank"
                                    key={item.title}
                                >
                                    {item.artist && (
                                        <ReactMarkdown
                                            source={`${item.artist}, ${item.title}`}
                                        />
                                    )}
                                    {item.subtitle && (
                                        <ReactMarkdown source={item.subtitle} />
                                    )}
                                    {item.year && (
                                        <ReactMarkdown source={item.year} />
                                    )}
                                </ResearchItem>
                            ))}
                        </ResearchColumn>
                    ))}
                </ResearchColumns>
            </ResearchInside>
        </Research>
    </>
);
