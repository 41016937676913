import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import useTimeout from "use-timeout";

import "Fonts/stylesheet.css";

import GlobalStyle from "Components/GlobalStyle";
import Content from "Components/Content";
import Header from "Components/Header";
import Research from "Components/Research";
import Intro from "Components/Intro";

import { useWindowWidth } from "@react-hook/window-size";

const Container = styled.div`
    position: fixed;
    overflow: auto;
    top: 2.4rem;
    left: 0;
    width: 100%;
    height: calc(100% - 2.4rem);
`;

const MainContent = styled.div`
    position: relative;
`;

function App() {
    const [isShowingArchive, setIsShowingArchive] = useState(false);
    const [isShowingIntro, setIsShowingIntro] = useState(false);

    const winWidth = useWindowWidth();
    const [specs, setSpecs] = useState({ numberOfTiles: 5 });
    const [contentHeight, setContentHeight] = useState(null);
    const contentRef = useRef();
    const archiveRef = useRef();
    const containerRef = useRef();

    useTimeout(() => setContentHeight(contentRef.current.scrollHeight), 3000);

    useEffect(() => {
        const numberOfTiles =
            winWidth > 1800
                ? 5
                : winWidth > 1200
                ? 6
                : winWidth > 1024
                ? 7
                : winWidth > 840
                ? 8
                : winWidth > 730
                ? 9
                : winWidth > 680
                ? 10
                : winWidth > 641
                ? 11
                : winWidth > 569
                ? 9
                : winWidth > 420
                ? 12
                : winWidth > 380
                ? 14
                : winWidth > 350
                ? 14
                : 20;
        setSpecs({ numberOfTiles });
        setContentHeight(contentRef.current.scrollHeight);
    }, [winWidth, isShowingArchive]);

    return (
        <>
            <GlobalStyle />
            <Header
                isShowingArchive={isShowingArchive}
                setIsShowingArchive={setIsShowingArchive}
                isShowingIntro={isShowingIntro}
                setIsShowingIntro={setIsShowingIntro}
                containerRef={containerRef}
                contentRef={contentRef}
            />
            <Container ref={containerRef}>
                <Intro
                    setIsShowingArchive={setIsShowingArchive}
                    setIsShowingIntro={setIsShowingIntro}
                    isShowingIntro={isShowingIntro}
                />
                <MainContent>
                    <Research
                        archiveRef={archiveRef}
                        contentHeight={contentHeight}
                        setContentHeight={setContentHeight}
                        isShowingArchive={isShowingArchive}
                    />
                    <Content
                        contentRef={contentRef}
                        isShowingArchive={isShowingArchive}
                        {...specs}
                    />
                </MainContent>
            </Container>
        </>
    );
}

export default App;
