import React from "react";
import styled from "styled-components";

const Header = styled.div`
    align-items: center;
    background: white;
    border-bottom: 1px solid var(--color-line);
    display: flex;
    flex-direction: row;
    height: 2.4rem;
    justify-content: space-between;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 4rem;
    z-index: 6;
    user-select: none;

    @media screen and (max-width: 640px) {
        padding: 0 1.25rem;
    }

    > div {
        flex: 1;
    }

    ::before {
        content: "";
        display: block;
        position: absolute;
        left: 2rem;
        width: 1px;
        height: 2.4rem;
        background: var(--color-line);
        @media screen and (max-width: 640px) {
            left: 0.5rem;
        }
    }

    ::after {
        content: "";
        display: block;
        position: absolute;
        right: 2rem;
        width: 1px;
        height: 2.4rem;
        background: var(--color-line);
        @media screen and (max-width: 640px) {
            right: 0.5rem;
        }
    }
`;

const Title = styled.div`
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 640px) {
        text-align: left;
    }
`;

const TitleCaps = styled.span`
    letter-spacing: 2px;
    padding-right: 5px;
`;

const TitleRest = styled.span`
    @media screen and (max-width: 640px) {
        display: none;
    }
`;

const Button = styled.button`
    height: 1.4rem;
    font-size: 0.8rem;
    border-radius: 0.7rem;
    cursor: pointer;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    transition: all 0.1s ease;
    :focus {
        outline: none;
    }
`;

const ButtonLabel = styled.div`
    font-size: 0.7rem;
    padding-right: 0.4rem;
    text-transform: uppercase;
    padding-top: 2px;
    letter-spacing: 1px;
    @media screen and (max-width: 640px) {
        display: none;
    }
`;

const TagButton = styled(Button)`
    background: ${(props) =>
        props.isOn ? "var(--color-red-button)" : "var(--color-red-button-off)"};
    &:hover {
        background: ${(props) =>
            props.isOn
                ? "var(--color-red-button-hover)"
                : "var(--color-red-button-off-hover)"};
    }
    text-transform: capitalize;
`;

const TagButtonLeft = styled(TagButton)`
    border-radius: 0.7rem 0 0 0.7rem;
`;
const TagButtonRight = styled(TagButton)`
    border-radius: 0 0.7rem 0.7rem 0;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    > * {
        margin-right: 2px;
    }
    justify-content: flex-end;

    @media screen and (max-width: 1023px) {
        /* display: none; */
    }
`;

const Left = styled.div`
    a {
        color: rgba(0, 0, 0, 0.5);
        text-decoration: none;
    }
    @media screen and (max-width: 1023px) {
        display: none;
    }
`;

export default ({
    containerRef,
    contentRef,
    isShowingArchive,
    setIsShowingArchive,
    setIsShowingIntro,
    isShowingIntro,
}) => (
    <Header isArchiveOn={isShowingArchive}>
        <Left>
            {/*
            <InfoButton
                onClick={() => {
                    if (!isShowingIntro) {
                        setIsShowingIntro(true);
                        containerRef.current.scrollTo(0, 0);
                    } else {
                        setIsShowingIntro(false);
                    }
                }}
            >
                {isShowingIntro ? "Hide" : "Show"} introduction
            </InfoButton>
            */}
            <a
                href="//open.acca.melbourne"
                rel="noopener noreferrer"
                target="_blank"
            >
                &lsaquo; ACCA Open
            </a>
        </Left>
        <Title
            onClick={() =>
                containerRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                })
            }
        >
            <TitleCaps>AOAULI</TitleCaps>
            <TitleRest>/ Léuli Eshrāghi</TitleRest>
        </Title>
        <Buttons>
            <ButtonLabel>Show:</ButtonLabel>
            <TagButtonLeft
                isOn={!isShowingArchive}
                onClick={() => setIsShowingArchive(false)}
            >
                Barkcloth
            </TagButtonLeft>
            <TagButtonRight
                isOn={isShowingArchive}
                onClick={() => setIsShowingArchive(true)}
            >
                Archive
            </TagButtonRight>
        </Buttons>
    </Header>
);
